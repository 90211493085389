<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="z-10 fixed inset-0 overflow-hidden" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col bg-white shadow-lg overflow-y-scroll">
                <div class="bg-gradient-to-r from-blue-500 to-blue-700 h-2"></div>
                <div class="px-6 pt-3 pb-4 bg-white shadow">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-xl font-bold text-gray-900 sm:text-2xl">
                      Select an NFT
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-600" @click="open = false">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex-1 overflow-y-auto p-4 sm:p-6">
                  <ApolloQuery
                    :query="listWalletItems"
                    :variables="{ id: walletId }"
                    v-slot="{ result: { data }, isLoading }">
                    <div v-if="isLoading" class="grid grid-cols-2 gap-6">
                      <div v-for="n in 16" :key="n" class="group animate-pulse">
                        <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden"></div>
                      </div>
                    </div>
                    <template v-else-if="data.walletItems.length">
                      <RadioGroup v-model="selectedToken">
                        <RadioGroupLabel class="sr-only">
                          Choose a token from your wallet
                        </RadioGroupLabel>
                        <div class="grid grid-cols-2 gap-6">
                          <RadioGroupOption as="template" v-for="item in data.walletItems" :key="item.id" :value="item.id" v-slot="{ checked }">
                            <div :class="[checked ? 'ring-2 ring-offset-2 ring-blue-500' : '', 'w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden']">
                              <img :src="item.thumbnailUrl" class="w-full h-full object-center object-cover group-hover:opacity-75" />
                              <RadioGroupLabel as="p" class="sr-only">
                                {{ item.drop }} {{ item.tokenSeq + 1 }}
                              </RadioGroupLabel>
                            </div>
                          </RadioGroupOption>
                        </div>
                      </RadioGroup>
                    </template>
                    <template v-else>
                      <div class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center">
                        <CubeTransparentIcon class="mx-auto h-10 w-10 text-gray-400" />
                        <span class="mt-2 block text-sm font-medium text-gray-900">
                          This wallet doesn't have any Diamond Hands Hotel NFTs
                        </span>
                      </div>
                    </template>
                  </ApolloQuery>
                </div>
                <div class="flex-shrink-0 px-6 pt-3 pb-4 bg-white border-t border-gray-200">
                  <div class="flex items-start justify-between">
                    <DBtn block :loading="loading" @click="save" primary color="blue">
                      Save
                    </DBtn>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { setProfilePhoto, ownProfile } from '@/gql/auth'
import { XIcon } from '@heroicons/vue/solid'
import { CubeTransparentIcon } from '@heroicons/vue/outline'
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption
} from '@headlessui/vue'
import { listWalletItems } from '@/gql/wallet'
import { DBtn } from '@ui'

const emit = defineEmits(['update:modelValue'])
const props = defineProps(['modelValue'])
const store = useStore()
const userId = store.getters['auth/id']

const selectedToken = ref(null)
const walletId = computed(() => {
  const wallet = store.getters['auth/activeWallet']
  return wallet ? wallet.id : undefined
})

const { mutate, loading } = useMutation(setProfilePhoto, () => ({
  variables: {
    tokenId: selectedToken.value,
    walletId: walletId.value,
  },
  update: (cache, { data: { profile } }) => {
    const variables = { id: userId }
    const data = cache.readQuery({
      query: ownProfile,
      variables,
    })
    cache.writeQuery({
      query: ownProfile,
      variables,
      data: {
        ...data,
        profile: {
          ...data.profile,
          ...profile,
        }
      },
    })
  },
}))

const save = async (e) => {
  e.preventDefault()
  try {
    if (!selectedToken.value) {
      notify({ type: 'error', text: 'Select an NFT to continue' })
      return
    }
    await mutate()
    notify({ type: 'success', text: 'Profile saved' })
  } catch (err) {
    notify({ type: 'error', title: 'Unexpected error', text: err.message })
  }
}

const open = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
})
</script>